import { FeatureEnum } from '@/features/game/levels/config/features'
import { Plugin } from '@/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fullApiSlice } from './api'
import { FeatureAccessUpdate } from '@/types/game/featureAccess'

const IS_NEW_USER_MODE = true
const lockedForNewUser = IS_NEW_USER_MODE ? false : true

type State = {
    featureAccessMap: { [key in FeatureEnum]?: boolean }
    plugins: Record<Plugin, boolean>
}

// store feature access, 0-unlocked but unused 1-unlocked and used
// don't store locked features

const initialState: State = {
    featureAccessMap: {
        'i.task-difficulty': true,
        kanban: true,
        'syllabus-parser': true,
        'student-grades': false,
        // 'completed-tasks-view': true,
        // 'i.task-estimated-time': true,
        // chronotype: false,
        // 'power-hours': false,
    },
    plugins: {
        alpha: false,
        student: false,
        beta: false,
        ubc: false,
        mobileApp: false, // if connected to mobile app
    },
}

const alphaFeatures: FeatureEnum[] = ['capture-list']
const betaFeatures: FeatureEnum[] = ['rule-of-three', 'brain-dump']

const unlockFeatures = (features: FeatureEnum[], state: State) => {
    for (let feat of features) {
        if (!(feat in state.featureAccessMap)) {
            state.featureAccessMap[feat] = false // unlock features
        }
    }
}

const enableFeatures = (features: FeatureEnum[], state: State) => {
    for (let feat of features) {
        state.featureAccessMap[feat] = true // enable features
    }
}

const levelSlice = createSlice({
    name: 'feature-access',
    initialState,
    reducers: {
        setFeatureAccess: (state, action: PayloadAction<FeatureAccessUpdate>) => {
            const { enable, unlock, lock } = action.payload
            if (enable) {
                for (let feat of enable) {
                    state.featureAccessMap[feat] = true
                }
            }
            if (unlock) {
                for (let feat of unlock) {
                    state.featureAccessMap[feat] = false
                }
            }
            if (lock) {
                for (let feat of lock) {
                    delete state.featureAccessMap[feat]
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(fullApiSlice.endpoints.getUser.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { level, plugins } = action.payload
            if (plugins) {
                for (const p of plugins) {
                    state.plugins[p] = true
                }
            }

            if (state.plugins['alpha']) {
                state.plugins['beta'] = true
                unlockFeatures(alphaFeatures, state)
            }
            if (state.plugins['beta']) {
                unlockFeatures(betaFeatures, state)
            }
        })

        builder.addMatcher(fullApiSlice.endpoints.getFeatureAccess.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { enabled, unlocked } = action.payload
            enableFeatures(enabled, state)
            unlockFeatures(unlocked, state)
        })

        builder.addMatcher(fullApiSlice.endpoints.getMobile.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { isPaired } = action.payload
            state.plugins['mobileApp'] = isPaired
        })
    },
})

export const { setFeatureAccess } = levelSlice.actions
export default levelSlice.reducer
